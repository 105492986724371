import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const MaintenancePage = lazy(() => import('src/pages/maintenance'));
const TrackingPage = lazy(() => import('src/pages/tracking'));

// ----------------------------------------------------------------------

export const commonRoutes = [
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: 'tracking', element: <TrackingPage /> },
    ],
  },
];
